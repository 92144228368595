export const Breakpoints = {
  ScreenXS: '575px',
  ScreenSM: '767px',
  ScreenMD: '991px',
  ScreenLG: '1199px',
  ScreenXL: '1399px',
  ScreenXXL: '1799px',
};

export const DELAY = {
  homePromo: 200,
  svgWithLink: 200,
  carouselInterval: 3000,
};

export const RouteNames = {
  Home: '/home',
  About: '/about',
  ContactUs: '/contact',
};

// export const ROUTE_TYPES = {
//   FOR_HOME: {
//     DEFAULT: 'forHomeNavigation',
//     SUB_NAVIGATION: {
//       OVERVIEW: {
//         DEFAULT: 'overview',
//         SUB_MENU: {
//           AUTOMATIC_DELIVERY: 'automaticDelivery',
//           PAYMENT: 'payment',
//           ORDER_FUEL_ONLINE: 'orderFuelOnline',
//           EQUIPMENT_PROTECTION_PLANS: 'equipmentProtectionPlans',
//           TANK_MONITORING: 'tankMonitoring',
//           MOVING: 'moving',
//         },
//       },
//       HEATING_OIL: 'heatingOil',
//       PROPANE: 'propane',
//       EQUIPMENT: {
//         DEFAULT: 'equipment',
//         SUB_MENU: {
//           OIL: 'oil',
//           PROPANE: 'propaneNavigation',
//           AIR_CONDITIONING: 'airConditioning',
//         },
//       },
//       PROPANE_EQUIPMENT: {
//         DEFAULT: 'propaneEquipment',
//         SUB_MENU: {
//           INDOOR_PRODUCTS: 'indoorproducts',
//           OUTDOOR_PRODUCTS: 'outdoorproducts',
//           CHALET_CAMPING_OUTDOORS: 'chaletcampingoutdoors',
//           OUT_DISTRIBUTORS: 'ourdistributors',
//         },
//       },
//       ORDER_FUEL_ONLINE: 'orderFuelOnline',
//       PROMOTIONS: {
//         DEFAULT: 'promotions',
//         SUB_MENU: {
//           CANEX: 'canex',
//           FREE_FILL: 'freeFill',
//           REFERRAL_PROGRAM: 'referralProgram',
//         },
//       },
//       CLIENT_SPACE: {
//         DEFAULT: 'ClientSpaceSubNav',
//         SUB_MENU: {
//           OPEN_ACCOUNT: 'openAnAccount',
//         },
//       },
//     },
//   },
//   FOR_BUSINESS: {
//     DEFAULT: 'forBusiness',
//     SUB_NAVIGATION: {
//       OVERVIEW: {
//         DEFAULT: 'overview',
//         SUB_MENU: {
//           AUTOMATIC_DELIVERY: 'automaticDelivery',
//           PAYMENT: 'payment',
//           ORDER_FUEL_ONLINE: 'orderFuelOnline',
//           EQUIPMENT_PROTECTION_PLANS: 'equipmentProtectionPlans',
//           TANK_MONITORING: 'tankMonitoring',
//           MOVING: 'moving',
//         },
//       },
//     },
//   },
// };

export const SECTIONS = {
  HOMEPAGE: {
    IMAGES_WITH_PATHLIST: 'imagesWithPathList',
    IMAGES_WITH_LINK_SECTION: 'listOfSection',
    PROMO_CONTENTLIST_ITEMS: 'referSolutionFeedbackContentList',
    IMAGES_CAROUSEL: 'imagesCarousel',
    IMAGE_WITH_PATH: 'imageWithPah',
    TEXT_WITH_PATH_FOR_MOBILE: 'textWithPathForMobile',
    LINK_WITH_PDF: 'linkWithPDF',
    DELIVERING_MORE_LIST: {
      DEFAULT: 'deliveringMoreForYou',
      PRODUCT_LIST: 'productList',
      HEADER: 'richTextWithImageLeft',
      EAGLE_CONTENT: 'richtextWithImageRight',
    },
    DEDICATED_HSE: {
      BACKGROUND_IMAGE: 'backoundImageWithText',
      HOME_CONTAINER: 'homeContainerWithoutImage',
    },
    DEDICATED_CUSTOMER_CARE: {
      IMAGE_CENTER: 'imageCenterDiv',
      RICHTEXTWITHPARAGRAPH: 'richTextWithParagraph',
      SINGLE_IMAGE: 'singleImage',
    },
    FOUNDATION: 'foundationContainer',
    CUSTOMER_CARE: 'homePageContentsList',
  },
  SLP: {
    FREE_FILL: {
      TITLE_DESKTOP: 'title-desktop',
      TITLE_MOBILE: 'title-mobile',
    },
  },
  FOOTER: {
    FOOTERTRADEMARKTEXT: 'footerTrademarkText',
    IMAGEWITHPATH: 'imagesWithPath',
  },
};
export const LocationTypes = {
  Branch: 'Branch',
  UltramarCardlock: 'Cardlock',
  PipelineCardlock: 'Pipeline',
};

export const locationCountries = ['All', 'NB', 'NL', 'NS', 'ON', 'QC'];

export const validHomePageRoutes = ['/', '/en', '/en/', '/fr', '/fr/'];

export const validLanguages = ['en', 'fr'];

export const validRegions = ['nb', 'on', 'ns', 'qc', 'nl'];
