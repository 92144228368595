/* eslint-disable jsx-a11y/img-redundant-alt */
import * as React from 'react';
import * as Styled from '../headerStyle';
import AccountTypePopup from '../../../common/accountTypePopup/accountTypePopup';
import SearchBoxPopup from '../../searchBoxPopup/searchBoxPopup';
import { graphql, useStaticQuery } from 'gatsby';
import Cookies from 'universal-cookie';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
  isValidURLLanguage,
  validNodeLocale,
} from '../../../utils';
import iconHeadClose from '../../../images/icon-head-close.png';
import { useLocation } from '@reach/router';

const HeadTop = ({ scrollPosition, showSubMenu, englishSlug, frenchSlug }) => {
  const unfilteredPopUpList = useStaticQuery(graphql`
    query PopUpListQuery {
      allContentfulUltramarCommercial(
        filter: { identifier: { type: { eq: "header" } } }
      ) {
        edges {
          node {
            node_locale
            title
            slug
            identifier {
              type
            }
            sections {
              ... on ContentfulListOfImageWithPath {
                id
                title
                type
                listOfImagesWithPath {
                  title
                  type
                  path
                  image {
                    url
                    title
                  }
                  richText {
                    raw
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const cookies = new Cookies();

  const location = useLocation();
  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);

  const selectedLanguage =
    isValidURLLanguage(urlLanguage) && validNodeLocale(urlLanguage)
      ? validNodeLocale(urlLanguage)
      : cookies.get('language')
      ? cookies.get('language')
      : 'en-US';

  const filteredPopUpList =
    unfilteredPopUpList?.allContentfulUltramarCommercial?.edges?.find(
      (edge) => edge?.node?.node_locale === selectedLanguage
    );
  const popUpList = filteredPopUpList?.node?.sections?.find(
    (item) => item.type === 'listOfHeaderIageWithPath'
  )?.listOfImagesWithPath;

  const [regionModal, setRegionModal] = React.useState(false);
  const [showOpenAccountModal, setShowOpenAccountModal] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [showClientSpaceModal, setShowClientSpaceModal] = React.useState(false);

  React.useEffect(() => {
    if (scrollPosition || showSubMenu) {
      setRegionModal(false);
      setShowOpenAccountModal(false);
      setShowSearch(false);
      setShowClientSpaceModal(false);
    }
  }, [scrollPosition, showSubMenu]);

  const handleOpenAccount = () => {
    setRegionModal(false);
    setShowOpenAccountModal((prev) => !prev);
    setShowSearch(false);
    setShowClientSpaceModal(false);
  };
  const handleRegionModal = () => {
    setRegionModal((prev) => !prev);
    setShowOpenAccountModal(false);
    setShowSearch(false);
    setShowClientSpaceModal(false);
  };
  const handleSearchModal = () => {
    setRegionModal(false);
    setShowOpenAccountModal(false);
    setShowSearch((prev) => !prev);
    setShowClientSpaceModal(false);
  };
  const handleClientSpaceModal = () => {
    setRegionModal(false);
    setShowOpenAccountModal(false);
    setShowSearch(false);
    setShowClientSpaceModal((prev) => !prev);
  };

  return (
    <Styled.HeadTop className="head-top">
      <ul>
        {popUpList?.map((item, index) => {
          return (
            <li key={index}>
              {item?.type === 'serachImage' ? (
                <>
                  <Styled.HeadTopAnchor
                    id="search-link"
                    className="search"
                    onClick={() => handleSearchModal()}
                  >
                    {!showSearch ? (
                      <img src={item?.image?.url} alt={item?.image?.title} />
                    ) : (
                      <img src={iconHeadClose} alt="Close" />
                    )}
                    {item?.title}
                  </Styled.HeadTopAnchor>

                  {showSearch && <SearchBoxPopup />}
                </>
              ) : item?.type === 'imageWithPopup' ? (
                <>
                  <Styled.HeadTopAnchor
                    id="openAnAccount-link"
                    className="pLeft-50"
                    onClick={() => handleOpenAccount()}
                  >
                    {!showOpenAccountModal ? (
                      <img src={item?.image?.url} alt={item?.image?.title} />
                    ) : (
                      <img src={iconHeadClose} alt="X" />
                    )}
                    {item?.title}
                  </Styled.HeadTopAnchor>
                  {showOpenAccountModal && (
                    <AccountTypePopup account data={item?.richText?.raw} />
                  )}
                </>
              ) : item?.type === 'regionImagewithPopup' ? (
                <>
                  <Styled.HeadTopAnchor
                    id="regionLanguage-link"
                    className="selectRegionLanguage"
                    onClick={() => handleRegionModal()}
                  >
                    <div className="selectRegionLanguage-language d-inline-block">
                      {!regionModal ? (
                        <img src={item?.image?.url} alt={item?.image?.title} />
                      ) : (
                        <img src={iconHeadClose} alt="Close" />
                      )}
                      {selectedLanguage === 'fr-CA' ? 'Français' : 'English'}
                    </div>
                    <div className="selectRegionLanguage-region d-inline-block">
                      {cookies.get('region')?.toUpperCase()}
                    </div>
                  </Styled.HeadTopAnchor>
                  {regionModal && (
                    <AccountTypePopup
                      region
                      englishSlug={englishSlug}
                      frenchSlug={frenchSlug}
                    />
                  )}
                </>
              ) : item?.type === 'clientSpaceImageWithPopUp' ? (
                <>
                  <Styled.HeadTopAnchor
                    id="clientSpace-link"
                    className="clientSpace"
                    onClick={() => handleClientSpaceModal()}
                  >
                    {!showClientSpaceModal ? (
                      <img src={item?.image?.url} alt={item?.image?.title} />
                    ) : (
                      <img src={iconHeadClose} alt="Close" />
                    )}
                    {item?.title}
                  </Styled.HeadTopAnchor>
                  {showClientSpaceModal && (
                    <AccountTypePopup account data={item?.richText?.raw} />
                  )}
                </>
              ) : (
                <Styled.HeadTopAnchor
                  href={
                    isExternalUrlHref(item?.path)
                      ? item?.path
                      : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + item?.path)
                  }
                  className="pLeft-50"
                  target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                >
                  <img src={item?.image?.url} alt={item?.image?.title} />
                  {item?.title}
                </Styled.HeadTopAnchor>
              )}
            </li>
          );
        })}
      </ul>
    </Styled.HeadTop>
  );
};

export default HeadTop;
