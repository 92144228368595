import { graphql, useStaticQuery } from 'gatsby';

const useMenu = (locale) => {
  const { allContentfulUltramarCommercial } = useStaticQuery(
    graphql`
      query NavigationBarQuery {
        allContentfulUltramarCommercial(
          filter: { identifier: { type: { eq: "navigationBar" } } }
        ) {
          edges {
            node {
              id
              node_locale
              sections {
                ... on ContentfulRegions {
                  id
                  abbreviation
                  title
                  sections {
                    ... on ContentfulNavigationBar {
                      id
                      title
                      type
                      imageWithPath {
                        image {
                          file {
                            url
                            fileName
                          }
                          title
                        }
                        path
                      }
                      navigations {
                        title
                        type
                        entryWithPath {
                          type
                          text
                          path
                        }
                        subNavigations {
                          ... on ContentfulSubNavigation {
                            id
                            type
                            textWithPath {
                              path
                              text
                              type
                            }
                            subMenusWithPaths {
                              __typename
                              ... on ContentfulTextWithPath {
                                type
                                text
                                path
                              }
                            }
                          }
                          ... on ContentfulTextWithPath {
                            id
                            type
                            text
                            path
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const menuFilteredWithLanguage = allContentfulUltramarCommercial?.edges?.find(
    (edge) => edge?.node?.node_locale === locale
  );
  if (menuFilteredWithLanguage) {
    return menuFilteredWithLanguage?.node?.sections;
  }
  return allContentfulUltramarCommercial?.edges[0]?.node?.sections;
};

export default useMenu;
