import styled from 'styled-components';

export const MainContainer = styled.div`
  display: block;
  position: fixed;
  z-index: 98;
  right: 20px;
  width: 30px;
  height: 17px;
  bottom: 20px;
  @media (max-width: 1399px) {
    bottom: 17px;
  }
  @media (max-width: 1199px) {
    bottom: 70px;
  }
  @media (max-width: 767px) {
    bottom: 20px;
  }
`;
export const ScrollAnchor = styled.a`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
`;
export const ScrollImage = styled.img`
  border: 0;
  vertical-align: top;
  width: 30px;
  height: auto;
  transform: rotate(180deg);
`;
