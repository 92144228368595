import React from 'react';
import * as Styled from '../headerStyle';
import IconArrowDown from '../../../images/icon-arrow-down.png';
import HeadBottomLogo from '../headBottomLogo/headBottomLogo';
import SubNavigation from './subNavigations/subNavigations';
import HeadTop from '../headTop/headTop';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
  isSmallDevice,
} from '../../../utils';
import { useLocation } from '@reach/router';
import { validLanguages } from '../../../constants';

const HeadBottom = ({
  showNav,
  showSubMenu,
  navigationData,
  onMouseEnter,
  onMouseLeave,
  hoverIndex,
  imageData,
  englishSlug,
  frenchSlug,
}) => {
  const location = useLocation();
  return (
    <Styled.HeadBottomNav
      $display={showNav}
      onMouseLeave={() => !isSmallDevice() && onMouseLeave()}
    >
      <div className="d-none d-xl-block">
        <HeadBottomLogo imageData={imageData} />
      </div>
      <Styled.HeadBottomList>
        {navigationData?.navigations?.map((nav, index) => (
          <Styled.HeadBottomListItem
            className={
              location?.pathname
                ?.split('/')
                ?.find((i) => !validLanguages?.includes(i) && i)
                ?.includes(
                  nav?.entryWithPath?.path
                    ?.split('/')
                    ?.find((i) => !validLanguages?.includes(i) && i)
                )
                ? 'active'
                : ''
            }
            key={index}
            onMouseEnter={() => {
              !isSmallDevice() && onMouseEnter(nav?.entryWithPath?.text, index);
            }}
            onMouseOut={() => {
              !isSmallDevice() && !nav?.subNavigations && onMouseLeave();
            }}
          >
            <Styled.HeadBottomArrow
              src={IconArrowDown}
              className={
                nav.subNavigations
                  ? hoverIndex === index
                    ? 'active'
                    : ''
                  : 'd-none'
              }
              onClick={() => {
                isSmallDevice() &&
                  (hoverIndex === index
                    ? onMouseLeave()
                    : hoverIndex === null ||
                      hoverIndex === false ||
                      hoverIndex !== index
                    ? onMouseEnter(nav?.entryWithPath?.text, index)
                    : onMouseLeave());
              }}
            />
            <Styled.HeadBottomAnchor
              href={
                isSmallDevice() && nav.subNavigations
                  ? null
                  : isExternalUrlHref(nav?.entryWithPath?.path)
                  ? nav?.entryWithPath?.path
                  : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + nav?.entryWithPath?.path)
              }
              target={
                isExternalUrl(nav?.entryWithPath?.path) ? '_blank' : '_self'
              }
              onClick={() => {
                isSmallDevice() &&
                  (hoverIndex === index
                    ? onMouseLeave()
                    : hoverIndex === null ||
                      hoverIndex === false ||
                      hoverIndex !== index
                    ? onMouseEnter(nav?.entryWithPath?.text, index)
                    : onMouseLeave());
              }}
            >
              {nav?.entryWithPath?.text}
            </Styled.HeadBottomAnchor>
            {nav.subNavigations && showSubMenu && index === hoverIndex && (
              <SubNavigation
                subNav={nav.subNavigations}
                showSubMenu={showSubMenu}
                onMouseLeave={onMouseLeave}
              />
            )}
          </Styled.HeadBottomListItem>
        ))}
      </Styled.HeadBottomList>
      <div className="d-block d-md-none">
        <HeadTop englishSlug={englishSlug} frenchSlug={frenchSlug} />
      </div>
    </Styled.HeadBottomNav>
  );
};

export default HeadBottom;
