import { graphql, useStaticQuery } from 'gatsby';
import Cookies from 'universal-cookie';
import { useLocation } from '@reach/router';
import { isValidURLLanguage, validNodeLocale } from '../utils';

const useContentfulAssets = (assetId) => {
  const cookies = new Cookies();
  const location = useLocation();

  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);

  const locale =
    isValidURLLanguage(urlLanguage) && validNodeLocale(urlLanguage)
      ? validNodeLocale(urlLanguage)
      : cookies.get('language')
      ? cookies.get('language')
      : 'en-US';

  const { assetsArray } = useStaticQuery(
    graphql`
      query allContentfulAssets {
        assetsArray: allContentfulAsset {
          edges {
            node {
              node_locale
              contentful_id
              title
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const asset = assetsArray?.edges?.find((edge) => {
    return (
      edge?.node?.contentful_id === assetId &&
      edge?.node?.node_locale === locale
    );
  });
  return asset;
};

export default useContentfulAssets;
