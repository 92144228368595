import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './searchBoxPopupStyles';
import Cookies from 'universal-cookie';

const SearchBoxPopup = () => {
  const [value, setValue] = useState('');
  const [translation] = useTranslation();
  const cookies = new Cookies();
  return (
    <Styled.SearchBoxModal>
      <Styled.SearchBoxModalArrow />
      <Styled.SearchBox>
        <form
          autocomplete="off"
          action={
            cookies.get('language') === 'fr-CA'
              ? '/fr/resultats-de-recherche/'
              : '/en/search-results/'
          }
        >
          <Styled.SearchBoxInput>
            <input
              name="q"
              id="search"
              type="text"
              placeholder={translation('HelpYouFind')}
              value={value}
              autoFocus
              onChange={(e) => setValue(e?.target?.value)}
            ></input>
          </Styled.SearchBoxInput>
          <Styled.SearchSubmit>
            <Styled.SearchInput type="submit" value=""></Styled.SearchInput>
          </Styled.SearchSubmit>
        </form>
      </Styled.SearchBox>
    </Styled.SearchBoxModal>
  );
};
export default SearchBoxPopup;
