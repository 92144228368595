import styled from 'styled-components';
import IconSearchLarge from '../../images/icon-search-large.png';

export const SearchBoxModal = styled.div`
  display: block;
  left: -190px;
  width: 500px;
  position: absolute;
  z-index: 99;
  top: 60px;
  height: auto;
  padding: 40px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  overflow: visible;

  @media (max-width: 767px) {
    left: -150px;
    width: 300px;
    padding: 15px 20px 20px 20px;
  }
`;

export const SearchBoxModalArrow = styled.div`
  display: block;
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  transform: rotate(45deg);
  transition: all 0.25s ease-in-out;
`;
export const SearchBox = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
`;

export const SearchBoxInput = styled.div`
  float: left;
  width: 85%;
  input {
    width: 100%;
    height: 60px;
    margin: 0 !important;
    padding: 14px 17px 14px 0 !important;
    font-family: 'HelveticaNeue-Light', arial, sans-serif;
    font-size: 25px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.6);
    background: none;
    border: none;
    border-bottom: 1px solid #ffc905;
    -webkit-appearance: none;
    border-radius: 0;
    ::placeholder {
      opacity: 0.5;
    }
    @media (max-width: 767px) {
      height: 40px;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;
export const SearchSubmit = styled.div`
  float: right;
  width: 15%;
  height: 60px;
  margin: 0;
  @media (max-width: 767px) {
    height: 40px;
  }
`;
export const SearchInput = styled.input`
  width: 100%;
  height: 60px;
  background: url(${IconSearchLarge}) no-repeat center right;
  background-size: 39px auto;
  border: none;
  cursor: pointer;
  border: none;
  border-bottom: 1px solid #fff;
  -webkit-appearance: none;
  border-radius: 0;
  @media (max-width: 767px) {
    height: 40px;
    background-size: 25px auto;
  }
`;
