import styled from 'styled-components';
import BgSubMenu from '../../images/bg-submenu.jpg';
import AppColors from '../../styles/colors';
//Header

export const HeadBottomLogo = styled.div`
  float: left;
  width: 450px;
  margin-top: -22px;
  transition: all 0.5s ease-out;

  @media (max-width: 1830px) {
    width: 350px;
    margin-top: -12px;
  }
  @media (max-width: 1399px) {
    width: 300px;
    margin-top: -6px;
  }
  @media (max-width: 1240px) and (min-width: 1200px) {
    width: 260px !important ;
  }
  @media (max-width: 1199px) {
    float: none;
    width: 350px;
    margin: 30px auto 0 auto;
  }
  @media (max-width: 991px) {
    float: left;
    width: 400px;
    margin-bottom: 30px;
    position: relative;
    z-index: 9;
  }
  @media (max-width: 767px) {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

export const Header = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  width: 100%;
  min-height: 0;
  padding: 0 110px;
  background: ${AppColors.White};
  transition: all 0.5s ease-out;
  background: url(${BgSubMenu}) no-repeat top center;
  &.home {
    top: 62px;
    @media (max-width: 991px) {
      top: 0;
    }
  }
  &.scrolled {
    top: -85px;
    padding-bottom: 15px;
    box-shadow: 0 0 40px rgb(0 0 0 / 15%);
    ${HeadBottomLogo} {
      width: 350px;
      margin-top: -2px;
    }
  }
  &.open {
    min-height: 575px;
    box-shadow: 0 0 40px rgb(0 0 0 / 25%);
    @media (max-width: 1300px) {
      min-height: 675px;
    }
  }

  @media (min-width: 1921px) {
    width: 1920px;
  }
  @media (max-width: 1830px) {
    padding-left: 80px;
    padding-right: 80px;
    &.scrolled {
      ${HeadBottomLogo} {
        width: 300px;
        margin-top: 0;
      }
    }
  }
  @media (max-width: 1399px) {
    text-align: center;
    padding-left: 80px;
    padding-right: 80px;
    &.scrolled {
      ${HeadBottomLogo} {
        margin-top: 3px;
      }
    }
  }
  @media (max-width: 1199px) {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    background-position: center 100px;
    &.scrolled {
      top: -154px;
    }
  }
  @media (max-width: 991px) {
    position: relative;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    padding: 25px;
    background: ${AppColors.DarkBlue};
  }
`;
export const HeadTop = styled.div`
  position: relative;
  z-index: 3;
  float: right;
  width: 936px;
  padding: 35px 0 25px 0;
  text-align: center;
  @media (max-width: 1830px) {
    width: 856px;
  }
  @media (max-width: 1399px) {
    width: 760px;
    padding-top: 30px;
  }
  @media (max-width: 1199px) {
    float: none;
    margin: 0 auto;
    padding-top: 20px;
  }
  @media (max-width: 991px) {
    width: auto;
  }
  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      position: relative;
      margin: 0;
      padding: 0;
      list-style: none;
      @media (max-width: 767px) {
        padding-bottom: 15px;
      }
    }
  }
`;

export const HeadTopAnchor = styled.a`
  position: relative;
  display: block;
  margin: 0;
  padding: 13px 16px 13px 42px;
  list-style: none;
  font-family: 'HelveticaNeue-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 13px 16px 13px 42px;
    font-size: 14px;
    line-height: 14px;
  }
  @media (max-width: 767px) {
    font-size: 17px;
    line-height: 17px;
    color: ${AppColors.White};
    border: none;
  }
  :hover {
    color: rgba(0, 0, 0, 0.5);
    img {
      left: 12px;
    }
  }
  img {
    position: absolute;
    top: 7px;
    left: 5px;
    width: auto;
    height: 26px;
    transition: all 0.25s ease-out;
  }
  &.pLeft-50 {
    padding-left: 50px;
  }
  &.selectRegionLanguage {
    .selectRegionLanguage-language {
      padding-right: 12px;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      @media (max-width: 767px) {
        border-right: 1px solid ${AppColors.Yellow};
      }
    }
    .selectRegionLanguage-region {
      position: relative;
      padding-left: 8px;
      padding-right: 0;
    }
  }

  &.clientSpace {
    padding-left: 47px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    img {
      left: 12px;
    }
    :hover {
      border: 1px solid ${AppColors.Yellow};
      img {
        left: 17px;
      }
    }
    @media (max-width: 767px) {
      border: none;
      background: ${AppColors.DarkBlue};
    }
  }
`;

export const Clear = styled.div`
  clear: both;
`;

//Head  Top

export const HeadMobile = styled.div`
  display: none;
  @media (max-width: 1199px) {
    display: block;
  }
  @media (max-width: 991px) {
    width: 550px;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const HeadMobileLogo = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    position: relative;
    z-index: 99999999;
    float: left;
    width: 55%;
    text-align: left;
    img {
      width: auto;
      height: 70px;
    }
  }
`;

export const HeadNavMobile = styled.div`
  display: none;

  @media (max-width: 991px) {
    display: block;
    position: relative;
    z-index: 9999;
    float: right;
    margin-top: 45px;
  }

  @media (max-width: 767px) {
    float: right;
    width: 45%;
    margin-top: 5px;
  }
  .navCircle {
    @media (max-width: 991px) {
      position: relative;
      width: 60px;
      height: 60px;
      border: 3px solid ${AppColors.DarkBlue};
      border-radius: 50%;
      cursor: pointer;
    }
    @media (max-width: 767px) {
      float: right;
      border-color: ${AppColors.White};
    }

    .navHamburger {
      @media (max-width: 991px) {
        position: absolute;
        left: 0;
        right: 0;
        width: 26px;
        height: 3px;
        margin: auto;
        background: ${AppColors.DarkBlue};
        border-radius: 3px;
        transition: all 0.25s ease-out;
      }
      @media (max-width: 767px) {
        background: ${AppColors.White};
      }

      &.bar1 {
        top: 18px;
        &.open {
          width: 32px;
          transform: rotate(45deg) translateX(6px) translateY(5px);
        }
      }
      &.bar2 {
        top: 26px;
        width: 32px;
        &.open {
          width: 0;
        }
      }
      &.bar3 {
        top: 34px;
        &.open {
          width: 32px;
          transform: rotate(-45deg) translateX(6px) translateY(-6px);
        }
      }
    }
  }
`;

export const HeadBottomNav = styled.div`
  padding-top: 0;
  display: block;

  @media (max-width: 1199px) {
    max-width: 750px;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
  }
  @media (max-width: 991px) {
    display: ${(props) => (props.$display ? 'block' : 'none')};
    float: none;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    padding: 100px 50px;
    background: ${AppColors.LightBlue};
  }
  @media (max-width: 767px) {
    padding: 120px 35px 35px 35px;
  }
`;

export const HeadBottomList = styled.ul`
  float: right;
  padding: 0;
  margin: 0;
  height: 55px;
  background: ${AppColors.DarkBlue};
  border-radius: 6px;

  @media (max-width: 1199px) {
    float: none;
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    display: block;
    float: none;
    height: auto;
    padding: 0;
    margin: 0;
    background: none;
  }
`;

export const HeadBottomAnchor = styled.a`
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: block;
  height: 55px;
  margin: 0 25px;
  padding: 16px 0 0 0;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 24px;
  line-height: 24px;
  color: ${AppColors.White};
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    border-bottom: solid 4px ${AppColors.Yellow};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  @media (max-width: 1799px) {
    margin: 0 20px;
    font-size: 24px;
    line-height: 24px;
  }

  @media (max-width: 1399px) {
    padding-top: 18px;
    font-size: 20px;
    line-height: 20px;
  }
  @media (max-width: 991px) {
    display: block;
    height: auto;
    padding: 12px 0;
    margin: 0;
    font-family: 'MrAlex', arial, sans-serif;
    font-size: 35px;
    line-height: 35px;
    color: ${AppColors.White};
    text-align: center;
  }

  @media (max-width: 767px) {
    padding: 10px 0;
    font-size: 32px;
    line-height: 32px;
  }
`;

export const HeadBottomListItem = styled.li`
  float: left;
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
  :first-child {
    ${HeadBottomAnchor} {
      margin-left: 40px;
    }
  }
  :nth-last-child(2) {
    ${HeadBottomAnchor} {
      margin-right: 40px;
    }
  }
  :nth-child(5) > ul {
    width: 270px;
    @media (max-width: 991px) {
      width: auto;
    }
  }
  :last-child {
    background: ${AppColors.LightBlue};
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    ${HeadBottomAnchor} {
      margin: 0 40px;
    }
  }
  @media (min-width: 992px) {
    &.active {
      ${HeadBottomAnchor} {
        color: ${AppColors.Yellow};
      }
    }
  }

  :hover {
    ${HeadBottomAnchor} {
      color: ${AppColors.Yellow};
      border-bottom: solid 4px ${AppColors.Yellow};
    }
  }
  @media (max-width: 1830px) {
    :first-child {
      ${HeadBottomAnchor} {
        margin-left: 30px;
      }
    }
    :nth-last-child(2) {
      ${HeadBottomAnchor} {
        margin-right: 20px;
      }
    }
    :last-child {
      ${HeadBottomAnchor} {
        margin: 0 30px;
      }
    }
  }
  @media (max-width: 991px) {
    float: none;
    padding: 0;
    margin: 0;
    list-style: none;
    :first-child {
      ${HeadBottomAnchor} {
        margin-left: 0;
      }
    }
    :nth-last-child(2) {
      ${HeadBottomAnchor} {
        margin-right: 0;
      }
    }
    :last-child {
      background: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      ${HeadBottomAnchor} {
        margin: 0;
      }
    }
    :hover {
      ${HeadBottomAnchor} {
        border-bottom: 0;
        color: ${AppColors.Yellow};
      }
    }
  }
`;

export const HeadBottomArrow = styled.img`
  display: none;
  border: 0;
  vertical-align: top;
  &.active {
    transform: rotate(180deg);
  }
  @media (max-width: 991px) {
    display: block;
    position: absolute;
    z-index: 99;
    top: 22px;
    right: 20px;
    width: 18px;
    height: auto;
    transition: all 0.25s ease-out;
  }
`;
