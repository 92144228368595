import styled from 'styled-components';
import AppColors from '../../styles/colors';

export const Foot = styled.div`
  margin-top: 40px;
`;

export const Clear = styled.div`
  clear: both;
`;

export const FootSitemapContainer = styled.div`
  padding: 75px 0 55px 0;
  background: rgb(237, 237, 237);
  /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  @media (max-width: 767px) {
    display: none;
  }
`;

export const MainMenuTitleUL = styled.ul`
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const MainMenuTitleLI = styled.li`
  position: relative;
  float: left;
  width: 20%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 30px;
  line-height: 30px;
  color: ${AppColors.DarkBlue};
  :last-child {
    display: none;
  }
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const MainMenuTitle = styled.a`
  display: block;
  position: relative;
  margin-bottom: 40px;
  color: ${AppColors.DarkBlue};
  :hover {
    color: ${AppColors.Orange};
  }
  :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    width: 100px;
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    background: ${AppColors.Yellow};
    border-radius: 4px;
  }
`;

export const SubMenuListUL = styled.ul`
  padding: 0;
  margin: 0;
`;

export const SubMenuListLI = styled.li`
  list-style: none;
`;

export const SubMenuTitle = styled.a`
  display: block;
  padding: 0 0 8px 0;
  margin: 0;
  list-style: none;
  text-align: center;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 18px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.5);
  :hover {
    color: ${AppColors.DarkBlue};
  }
  @media (max-width: 1439px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const FootSitemap = styled.div`
  width: 1400px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 1439px) {
    width: 100%;
    padding-left: 70px;
    padding-right: 70px;
  }
  @media (max-width: 1023px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const FootBottom = styled.div`
  width: 1400px;
  padding: 55px 0 100px 0;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 767px) {
    margin-top: 100px;
    padding-bottom: 50px;
    background: rgb(237, 237, 237);
    /* Old browsers */
    background: -moz-linear-gradient(
      top,
      rgba(237, 237, 237, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(237, 237, 237, 1) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(237, 237, 237, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(237, 237, 237, 1) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(237, 237, 237, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(237, 237, 237, 1) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  @media (max-width: 1439px) {
    width: 100%;
  }
`;

export const FootBottomLeft = styled.div`
  float: left;
  width: 37%;
  @media (max-width: 1199px) {
    width: 36%;
  }
  @media (max-width: 991px) {
    width: 50%;
    padding-right: 40px;
  }
  @media (max-width: 767px) {
    float: none;
    width: 100%;
    padding-right: 0;
  }
`;

export const FootBottomLeftUL = styled.ul`
  float: right;
  width: 100%;
  padding: 0;
  margin: 0;
  @media (max-width: 767px) {
    float: none;
  }
`;

export const FootBottomLeftLI = styled.li`
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 24px;
  line-height: 24px;
  color: ${AppColors.LightBlue};
  @media (max-width: 991px) {
    float: none;
    width: 150px;
    display: inline-flex;
  }
  @media (max-width: 767px) {
    float: none;
    padding-bottom: 15px;
    width: 100%;
    display: block;
  }
`;

export const FootBottomMiddle = styled.div`
  float: left;
  width: 26%;

  @media (max-width: 1199px) {
    width: 28%;
  }
  @media (max-width: 991px) {
    float: none;
    width: 100%;
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    float: none;
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const FootBottomMiddleImg = styled.img`
  width: 200px;
  height: auto;
  margin-top: -100px;

  @media (max-width: 1023px) {
    width: 150px;
  }
  @media (max-width: 767px) {
    width: 125px;
    height: auto;
    margin-top: -110px;
  }
`;

export const FootBottomRight = styled.div`
  float: left;
  width: 37%;

  @media (max-width: 1199px) {
    width: 36%;
  }
  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    float: none;
    width: 100%;
  }
`;

export const FootBottomRightUL = styled.ul`
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  @media (max-width: 767px) {
    float: none;
  }
`;

export const FootBottomRightLI = styled.li`
  float: left;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 24px;
  line-height: 24px;
  color: ${AppColors.LightBlue};
  max-width: 300px;
  :first-child {
    padding-right: 40px;
  }
  :nth-child(2) {
    padding-right: 40px;
  }
  @media (max-width: 1399px) {
    max-width: 200px;
  }
  @media (max-width: 1199px) {
    max-width: 160px;
  }
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    float: none;
    padding-bottom: 15px;
    :first-child {
      padding-right: 0;
    }
  }
`;

export const FootBottomBottom = styled.div`
  padding: 40px 0 0 0;
  @media (max-width: 767px) {
    padding: 15px 35px 35px 35px;
    p br {
      display: none;
    }
  }
`;

export const FootBottomBottomP = styled.p`
  font-family: 'Gotham-Light', arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
`;

export const FootBottomBottomUL = styled.ul`
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const FootBottomBottomLI = styled.li`
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
`;

export const FootBottomBottomImg = styled.img`
  width: auto;
  height: 25px;
  margin: 0 7px;
`;
