import styled from 'styled-components';
import IconArrowBlue from '../../../../images/icon-arrow-light-blue.png';
import AppColors from '../../../../styles/colors';

export const SubMenuAnchor = styled.a`
  position: relative;
  display: block;
  padding: 4px 0 4px 25px;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  text-decoration: none;
  &.active {
    color: ${AppColors.LightBlue};
  }

  :after {
    background: url(${IconArrowBlue}) no-repeat top left;
    background-size: 12px auto;
    display: inline-block;
    position: absolute;
    top: 11px;
    left: -12px;
    width: 12px;
    height: 12px;
    content: '';
    transition: all 0.2s ease-out;
    transform: rotate(-90deg);
  }
  @media (max-width: 991px) {
    display: block;
    padding: 12px 25px 12px 10px;
    margin-right: 30px;
    font-family: 'HelveticaNeue-Light', arial, sans-serif;
    font-size: 24px;
    line-height: 24px;
    text-align: left;
    color: ${AppColors.White};
  }
`;

export const SubMenuListItem = styled.li`
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
  :hover {
    ${SubMenuAnchor} {
      color: ${AppColors.LightBlue};
      ::after {
        left: 0;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 0;
    margin: 0;
    list-style: none;
    :hover {
      ${SubMenuAnchor} {
        color: ${AppColors.White};
        ::after {
          display: none;
        }
      }
    }
  }
`;

export const SubMenuList = styled.ul`
  display: block;
  position: absolute;
  z-index: 99;
  top: 55px;
  left: 2px;
  width: 200px;
  margin: 0;
  padding: 25px 0;
  @media (max-width: 991px) {
    position: static;
    padding: 20px;
    width: 100%;
    margin: 0;
    background: ${AppColors.DarkBlue};
    border-top: 5px solid ${AppColors.Yellow};
  }
`;

export const SubMenuArrowImage = styled.img`
  display: none;
  border: 0;
  vertical-align: top;
  @media (max-width: 991px) {
    margin: 10px;
    display: block;
    position: absolute;
    z-index: 99;
    top: 10px;
    right: -10px;
    width: 18px;
    height: auto;
    transition: all 0.25s ease-out;
    &.active {
      transform: rotate(180deg);
    }
  }
`;

export const SubSubMenuList = styled.ul`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 200px;
  width: 250px;
  margin: 0;
  padding: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  @media (max-width: 991px) {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    padding: 10px 20px 20px 20px;
    margin: 0;
    background: rgba(0, 0, 0, 0.1);
    border-left: none;
  }
`;

export const SubSubMenuAnchor = styled.a`
  position: relative;
  display: block;
  padding: 4px 0 4px 25px;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  &.active {
    color: ${AppColors.LightBlue};
  }
  :hover {
    color: ${AppColors.LightBlue};
  }
  @media (max-width: 991px) {
    padding: 0;
    display: block;
    font-family: 'HelveticaNeue-Regular', arial, sans-serif;
    font-size: 18px;
    line-height: 18px;
    text-align: left;
    color: ${AppColors.White};
  }
`;

export const SubSubMenuListItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;

  @media (max-width: 991px) {
    padding: 10px 0;
    margin: 0;
    list-style: none;
  }
`;
