import React from 'react';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../../utils';
import * as Styled from '../headerStyle';

const HeadBottomLogo = ({ imageData }) => {
  return (
    <Styled.HeadBottomLogo>
      <a
        href={
          isExternalUrlHref(imageData?.path)
            ? imageData?.path
            : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + imageData?.path)
        }
        target={isExternalUrl(imageData?.path) ? '_blank' : '_self'}
        rel="noreferrer"
      >
        <img src={imageData?.image?.file?.url} alt={imageData?.image?.title} />
      </a>
    </Styled.HeadBottomLogo>
  );
};

export default HeadBottomLogo;
