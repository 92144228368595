import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import AppColors from './colors';
import IconCheck from '../images/icon-check.png';
import IconArrowYellowRight from '../images/icon-arrow-yellow-right.png';
import homeHouse from '../images/home-house.jpg';
//Common Styles
export const Body404 = styled.div`
  margin-top: 230px;
  text-align: center;
`;

export const HtmlSiteMapHeader = styled.h3`
  padding-left: ${(props) => props.$leftSpace * 30}px;
  font-size: 20px;
  line-height: 22px;
  margin: 0 0 15px 0;
  @media (max-width: 1199px) {
    padding-left: ${(props) => props.$leftSpace * 20}px;
  }
  @media (max-width: 767px) {
    padding-left: ${(props) => props.$leftSpace * 15}px;
  }
`;

export const MainContainer = styled.div`
  position: relative;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Body = styled.div`
  padding: 40px 110px 0px;

  @media (max-width: 1200px) {
    padding: 50px;
  }
  @media (max-width: 767px) {
    padding: 35px;
  }
`;

export const BodyWithPadding = styled.div`
  padding: 80px 110px 0px;

  @media (max-width: 1200px) {
    padding: 50px;
  }
  @media (max-width: 767px) {
    padding: 35px;
  }
`;

export const BlockQuotes = styled.blockquote`
  width: 45%;
  padding: 60px;
  margin: 60px;
  text-align: center;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  border-radius: 20px;
  float: right;
  @media (max-width: 991px) {
    width: auto;
    margin: 35px;
    float: none;
    display: block;
  }
`;

export const GreyBody = styled(Body)`
  padding: 40px 110px;
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
`;

export const Columns = styled.div`
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;
export const ColumnsHalfLeft = styled.div`
  float: left;
  width: 50%;
  padding-right: 35px;
  text-align: ${(props) => (props?.$textAlign ? 'center' : 'left')};
  @media (max-width: 991px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    ul {
      margin-bottom: 0;
    }
  }
  *:last-child {
    margin-bottom: 0;
  }
`;
export const ColumnsHalfRight = styled.div`
  text-align: ${(props) => (props?.$textAlign ? 'center' : 'left')};
  float: right;
  width: 50%;
  padding-left: 35px;
  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
    ul:first-child {
      margin-top: -30px;
    }
  }
  *:last-child {
    margin-bottom: 0;
  }
`;

export const ColumnsThird = styled.div`
  float: left;
  width: 33.33%;
  padding-right: 50px;
  @media (max-width: 991px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    ul:first-child {
      margin-top: -30px;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
  *:last-child {
    margin-bottom: 0;
  }
`;
export const ColumnsThirdMiddle = styled.div`
  float: left;
  width: 33.33%;
  padding-right: 50px;
  @media (max-width: 991px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    ul {
      margin-bottom: 0;
    }
  }
  *:last-child {
    margin-bottom: 0;
  }
`;

export const ColumnsOneThirdLeft = styled.div`
  float: left;
  width: 34%;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 30px;
  }
  *:last-child {
    margin-bottom: 0;
  }
`;
export const ColumnsTwoThirdRight = styled.div`
  float: right;
  width: 66%;
  padding-left: 75px;
  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
  }
`;
export const ColumnsOneThirdRight = styled.div`
  float: right;
  width: 34%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const ColumnsTwoThirdLeft = styled.div`
  float: left;
  width: 66%;
  padding-right: 75px;
  @media (max-width: 991px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
  }
`;
export const ColumnsQuarter = styled.div`
  float: left;
  width: 25%;
  padding-right: 50px;
  @media (max-width: 1199px) {
    float: left;
    width: 50%;
    padding-left: 0;
    padding-right: 40px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
`;
export const ColumnsThreeQuartersLeft = styled.div`
  float: left;
  width: 75%;
  padding-right: 75px;
  @media (max-width: 991px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
  }
`;
export const ColumnsOneQuartersRight = styled.div`
  float: right;
  width: 25%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
export const ColumnsOneQuartersLeft = styled.div`
  float: left;
  width: 25%;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
export const ColumnsThreeQuartersRight = styled.div`
  float: right;
  width: 75%;
  padding-right: 75px;
  @media (max-width: 991px) {
    width: 100%;
    padding-right: 0;
  }
`;

export const Clear = styled.div`
  clear: both;
`;
export const HeaderH2 = styled.h2`
  text-align: ${(props) => (props?.$textAlign ? 'left' : 'center')};
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const HeaderH3 = styled.h3`
  text-align: ${(props) => props.textAlign};
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 32px;
  line-height: 37px;
  color: #00a6e8;
  @media (max-width: 1399px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const HeadingH4 = styled.h4`
  padding: 0 0 15px 0;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: normal;
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const HeaderH6 = styled.h6`
  font-size: 22px;
  color: #00539f;
  margin: 0 0 20px 0;
  padding: 0;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-align: center;
`;

export const ParaText = styled.p`
  margin-bottom: 40px;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
  a {
    margin-bottom: 0 !important;
  }
`;
export const ParaTextSmall = styled.p`
  font-style: italic;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 1399px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 20px;
  }
`;

export const ImageDiv = styled.div`
  margin: 0 0 40px 0;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
`;
export const ImageStyle = styled.img`
  border: 0;
  vertical-align: top;
  margin: 0 0 40px 0;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
`;
export const FullImageStyle = styled(ImageStyle)`
  width: 100%;
  height: auto;
`;
export const GreyBlock = styled.blockquote`
  width: 100%;
  padding: 60px;
  margin-bottom: 0 !important;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  border-radius: 20px;
  @media (max-width: 1399px) {
    padding: 40px;
  }
  @media (max-width: 1399px) {
    padding: 30px;
  }
`;

export const ButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 15px 35px;
  margin-right: 10px;
  margin-bottom: 40px;
  font-family: 'MrAlex', sans-serif;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: bold;
  color: #00a6e8;
  border: 2px solid #00a6e8;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  @media (max-width: 1199px) {
    font-size: 25px;
    line-height: 25px;
  }

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 25px;
  }
  :hover {
    top: -5px;
    color: #f7991c;
    border-color: #f7991c;
    box-shadow: 0 5px 0 0 #f7991c;
  }
`;
export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: rgba(0, 0, 0, 0.15);
  margin: 60px 0;
  clear: both;
  @media (max-width: 1399px) {
    margin: 50px 0;
  }
`;
export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
`;
export const OrderedList = styled.ol`
  margin: 0 0 30px 0;
  padding: 0;
  list-style-type: none;
  counter-reset: standard-counter;
`;
export const ListItem = styled.li`
  padding: 0 0 15px 50px;
  background: url(${IconCheck}) no-repeat 0 1px;
  background-size: 30px auto;
  list-style: none;
  margin: 0;
  @media (max-width: 767px) {
    padding-top: 1px;
    padding-left: 40px;
    background-position: 0 0;
    background-size: 25px auto;
  }
`;
export const ListItemArrow = styled.li`
  padding: 0 0 15px 30px;
  background: url(${IconArrowYellowRight}) no-repeat 0 9px;
  background-size: 10px auto;
  list-style: none;
  margin: 0;
  @media (max-width: 1399px) {
    background-position: 0 9px;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
  }
`;

// HomeFooterComponent Styling
export const Account = styled.div`
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
`;

export const AccountClearFix = styled(Account)`
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;

export const AccountBoxInner = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 24px;
  line-height: 24px;
  color: ${AppColors.White};
  transition: all 0.25s ease-out;

  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const AccountBoxInnerImg = styled.img`
  width: 33px;
  height: auto;
  margin: -3px 15px 0 0;
`;

export const AccountBox = styled.a`
  display: block;
  float: left;
  width: 33.33%;
  height: 60px;
  line-height: 60px;
  background: ${AppColors.DarkBlue};
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
`;

export const HomeCustomerCare = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  background: url(${homeHouse}) no-repeat top center;
  background-size: cover;
  overflow: hidden;
  @media (max-width: 1023px) {
    padding: 75px 50px;
  }
  @media (max-width: 767px) {
    padding: 45px 35px;
    display: block;
    background: #fff;
  }
`;

export const HomeCustomerCareGraphics = styled.div`
  flex-basis: 36%;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 100px 0 75px 100px;
  text-align: center;
  @media (max-width: 1829px) {
    flex-basis: 33%;
    padding-top: 75px;
    padding-bottom: 75px;
  }
  @media (max-width: 1299px) {
    flex-basis: 50%;
  }
  @media (max-width: 1023px) {
    flex-basis: 100%;
    padding: 0 50px 50px 50px;
  }
`;

export const HomeCustomerCareGraphicsCCE = styled.img`
  width: 300px;
  height: auto;
  margin-bottom: 55px;
  @media (max-width: 1829px) {
    width: 75%;
    height: auto;
  }
  @media (max-width: 1299px) {
    width: 310px;
    height: auto;
  }
  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    max-width: 310px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const HomeCustomerCareGraphicsTRUCK = styled.img`
  width: 345px;
  height: auto;
  @media (max-width: 1829px) {
    width: 75%;
    height: auto;
  }
  @media (max-width: 1299px) {
    width: 310px;
    height: auto;
  }
  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    max-width: 310px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const HomeCustomerCareGraphicsText = styled.div`
  flex-basis: 36%;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 120px 100px 75px 0;
  text-align: center;

  p {
    a {
      display: inline-block;
      position: relative;
      top: 0;
      padding: 15px 35px;
      margin-right: 10px;
      font-family: 'MrAlex', sans-serif;
      font-size: 24px;
      line-height: 28px;
      color: #00a6e8;
      border: 2px solid #00a6e8;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
      border-radius: 100px;
      cursor: pointer;
      &:hover {
        top: -5px;
        color: #f7991c;
        border-color: #f7991c;
        box-shadow: 0 5px 0 0 #f7991c;
      }
    }
  }
  h2 {
    font-size: 60px;
    line-height: 60px;
    span {
      display: block;
      margin-bottom: 10px;
      font-size: 45px;
      line-height: 45px;
      color: #ffc905;
    }
    p {
      padding: 0 80px;
      font-size: 20px;
      line-height: 32px;
    }
  }

  @media (max-width: 1829px) {
    flex-basis: 33%;
    padding-top: 75px;
    padding-bottom: 75px;
    h2 {
      font-size: 47px;
      line-height: 47px;
      span {
        font-size: 38px;
        line-height: 38px;
      }
    }
    p {
      padding: 0;
    }
  }
  @media (max-width: 1439px) {
    h2 {
      font-size: 40px;
      line-height: 40px;
      span {
        font-size: 30px;
        line-height: 30px;
      }
    }
    p {
      font-size: 18px;
      line-height: 28px;
      a {
        font-size: 20px;
        line-height: 24px;
        &:hover {
          top: -5px;
          color: #f7991c;
          border-color: #f7991c;
          box-shadow: 0 5px 0 0 #f7991c;
        }
      }
    }
  }
  @media (max-width: 1229px) {
    flex-basis: 50%;
    padding-top: 120px;
    h2 {
      font-size: 60px;
      line-height: 60px;
      span {
        font-size: 45px;
        line-height: 45px;
      }
    }
    p {
      .button {
        font-size: 25px;
        line-height: 25px;
      }
      .button:before {
        top: 17px;
      }
      .button:after {
        width: 130px;
        height: 50px;
      }
    }
  }
  @media (max-width: 1023px) {
    flex-basis: 100%;
    padding: 0;
    h2 {
      font-size: 50px;
      line-height: 50px;
      span {
        font-size: 35px;
        line-height: 35px;
      }
    }
    a.button {
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media (max-width: 767px) {
    h2 {
      font-size: 45px;
      line-height: 45px;
      span {
        font-size: 35px;
        line-height: 35px;
      }
    }
    a {
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .button {
      font-size: 22px;
      line-height: 25px;
    }

    .button:before {
      top: 17px;
    }

    .button:after {
      width: 90px;
      height: 50px;
    }
  }
`;

export const HomeSplit = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: 767px) {
    display: block;
  }
`;

export const HomeSplitFoundationImage = styled.img`
  width: 294px;
  height: auto;
  margin: 0 0 60px 0;
  @media (max-width: 1829px) {
    width: 220px;
  }
  @media (max-width: 1299px) {
    width: 175px;
  }
  @media (max-width: 1023px) {
    width: 250px;
  }
`;

export const HomeSplitTitle = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 767px) {
    margin-bottom: 20px;
    br {
      display: none;
    }
  }
`;

export const HomeSplitHSE = styled.div`
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 1;
  text-align: center;
  padding: 120px 100px 120px 100px;
  @media (max-width: 1299px) {
    height: 110px;
  }
  @media (max-width: 1023px) {
    flex-basis: 100%;
    padding: 75px 50px;
  }
  @media (max-width: 767px) {
    padding: 45px 35px 10px 35px;
  }
  img {
    width: auto;
    height: 230px;
    margin: 50px 0 74px 0;
    @media (max-width: 1829px) {
      height: 140px;
    }
    @media (max-width: 1299px) {
      height: 140px;
    }
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-right: 0;
      }
      &:nth-child(2) {
        margin-right: 10px;
      }
      &:nth-child(3) {
        margin-right: 0;
      }
    }
    @media (max-width: 767px) {
      &:nth-child(2) {
        margin-right: 15px;
      }
    }
  }
  p {
    .button {
      display: inline-block;
      position: relative;
      top: 0;
      padding: 15px 35px;
      margin-right: 10px;
      font-family: 'MrAlex', sans-serif;
      font-size: 24px;
      line-height: 28px;
      color: #00a6e8;
      border: 2px solid #00a6e8;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
      border-radius: 100px;
      cursor: pointer;
      @media (max-width: 1439px) {
        font-size: 20px;
        line-height: 24px;
      }
      @media (max-width: 1329px) {
        font-size: 25px;
        line-height: 25px;
        &:before {
          top: 17px;
        }
        &:after {
          width: 130px;
          height: 50px;
        }
      }
      @media (max-width: 767px) {
        font-size: 22px;
        line-height: 25px;
        &:before {
          top: 17px;
        }

        &:after {
          width: 90px;
          height: 50px;
        }
      }
    }
    .button:hover {
      top: -5px;
      color: #f7991c;
      border-color: #f7991c;
      box-shadow: 0 5px 0 0 #f7991c;
      @media (max-width: 1439px) {
        top: -5px;
        color: #f7991c;
        border-color: #f7991c;
        box-shadow: 0 5px 0 0 #f7991c;
      }
    }
  }
`;

export const ImageRight = styled.img`
  float: right;
  margin: 0 0 40px 60px;
  @media (max-width: 767px) {
    display: block;
    float: none;
    max-width: 300px;
    width: 100% !important;
    height: auto !important;
    margin: 0 0 30px 0;
  }
`;

export const ImageLeft = styled.img`
  float: left;
  margin: 0 60px 40px 0;
  @media (max-width: 767px) {
    display: block;
    float: none;
    max-width: 300px;
    width: 100% !important;
    height: auto !important;
    margin: 0 0 30px 0;
  }
`;

export const ImageFull = styled.img`
  width: 100%;
  height: auto;
  margin: 0 0 40px 0;
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
  }
`;

export const PImageFull = styled(ImageFull)`
  margin-bottom: 0;
`;
export const ColumnThird = styled(Col)`
  position: relative;
  padding-right: 50px !important;
  :last-child {
    padding-right: 0px !important;
  }
  @media (max-width: 991px) {
    width: 100%;
    padding: 0px !important;
    margin-bottom: 30px !important;

    ul:first-child {
      margin-top: -30px !important;
    }
  }
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00a6e8;
  transition: 0.25s ease-out;
`;

export const RightFloatContainer = styled.div`
  width: 30%;
  position: relative;
  z-index: 1;
  float: right;
  margin: 80px 110px 0 75px;
  @media (max-width: 991px) {
    float: none;
    width: 100%;
    padding: 50px 50px 0 50px;
    margin: 0;
  }
`;
export const SubText = styled.h3`
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 32px;
  line-height: 37px;
  color: #00a6e8;
`;

export const SmallImageStyle = styled.img`
  width: 100%;
  height: auto;
  margin: 0 0 40px 0;
`;

export const GreyCardContentListCol = styled(Col)`
  margin-bottom: 60px;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
`;

export const BlueCardHeading = styled.h1`
  text-align: center;
  position: relative;
  font-family: 'MrAlex', sans-serif;
  font-size: 4vw;
  line-height: 4vw;
  color: #fff;
  code {
    color: #ffc905;
    font-family: 'MrAlex', sans-serif;
    font-size: 4vw;
    line-height: 4vw;
  }
  @media (max-width: 1199px) {
    font-size: 40px;
    font-size: 80px;
    line-height: 80px;
    code {
      font-size: 80px;
      line-height: 80px;
      color: #ffc905;
      font-family: 'MrAlex', sans-serif;
    }
  }
  @media (max-width: 991px) {
    font-size: 80px;
    line-height: 80px;
    margin-bottom: 30px;
    code {
      font-size: 80px;
      line-height: 80px;
      color: #ffc905;
      font-family: 'MrAlex', sans-serif;
    }
  }
  @media (max-width: 767px) {
    font-size: 50px;
    line-height: 50px;
    code {
      font-size: 50px;
      line-height: 50px;
      color: #ffc905;
      font-family: 'MrAlex', sans-serif;
    }
  }
`;
export const BlueCardHeading3 = styled.h3`
  position: relative;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 32px;
  line-height: 37px;
  color: #fff;
  code {
    color: #ffc905;
    font-family: 'HelveticaNeue-Light', sans-serif;
    font-size: 32px;
    line-height: 37px;
  }
  @media (max-width: 1439px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
    code {
      color: #ffc905;
      font-family: 'HelveticaNeue-Light', sans-serif;
      font-size: 28px;
      line-height: 32px;
    }
  }
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
    code {
      font-size: 25px;
      line-height: 30px;
      color: #ffc905;
      font-family: 'HelveticaNeue-Light', sans-serif;
    }
  }
`;
export const GreyMainContainer = styled.div`
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SpanStyle = styled.span`
  position: absolute;
  top: -200px;
  @media (max-width: 991px) {
    top: -50px;
  }
`;
export const BlogColStyle = styled(Col)`
  padding: 0;
  margin: 0;
`;

export const SLPMainContainer = styled.div`
  position: relative;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin: 0 auto;
  padding: 0;
  justify-content: center;

  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const FullWidthImageStyle = styled.img`
  border: 0;
  vertical-align: top;
  margin: 0 0 35px 0;
  height: auto;
  width: 100%;
  max-width: 900px;
`;
