import React, { useState } from 'react';
import * as Styled from './accountTypePopupStyles';
import Cookies from 'universal-cookie';
import useRegionLanguage from '../../hooks/useRegionLanguage';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../utils';

const AccountTypePopup = ({
  data,
  region,
  account,
  englishSlug,
  frenchSlug,
}) => {
  const cookies = new Cookies();
  const date = new Date();
  const regionLanguageUnfilteredData = useRegionLanguage();
  const regionLanguageFilteredData =
    regionLanguageUnfilteredData?.allContentfulUltramarCommercial?.edges.find(
      (edge) =>
        cookies.get('language')
          ? edge?.node?.node_locale === cookies.get('language')
          : edge?.node?.node_locale === 'en-US'
    );
  const regionData = regionLanguageFilteredData?.node?.sections;

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        node?.content?.find((node) => node?.nodeType?.includes('hyperlink')) ? (
          <p className="m-0">{children}</p>
        ) : (
          <Styled.AccountTypeModalPara>{children}</Styled.AccountTypeModalPara>
        ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.AccountTypeButton
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                  process.env.GATSBY_DOMAIN_URL + node?.data?.uri
                )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.AccountTypeButton>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node.data.target.sys.id);
        return (
          <Styled.AccountTypeButton href={asset.node.file.url}>
            {children}
          </Styled.AccountTypeButton>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <Styled.AccountTypeModal $region={!!region}>
      <Styled.AccountTypeModalArrow />
      {account && (
        <Styled.AccountTypeSelect>
          {data &&
            documentToReactComponents(JSON.parse(data), optionsMainStyle)}
        </Styled.AccountTypeSelect>
      )}
      {region && (
        <Styled.RegionLanguageTable>
          {regionData?.map((item, index) => (
            <Styled.RegionLanguageRow key={index}>
              <Styled.RegionLanguageCell
                className={`${
                  item?.abbreviation === cookies.get('region')?.toUpperCase() &&
                  'active'
                }`}
              >
                {item?.title}
              </Styled.RegionLanguageCell>
              <Styled.RegionLanguageCell>
                <Styled.RegionLanguageLink
                  onClick={() => {
                    cookies.set('region', item?.abbreviation?.toLowerCase(), {
                      path: '/',
                      expires: new Date(
                        date.getFullYear() + 1,
                        date.getMonth(),
                        date.getDate()
                      ),
                    });
                    cookies.set('language', 'en-US', {
                      path: '/',
                      expires: new Date(
                        date.getFullYear() + 1,
                        date.getMonth(),
                        date.getDate()
                      ),
                    });
                    englishSlug
                      ? (window.location.href = englishSlug)
                      : window.location.reload();
                  }}
                >
                  EN
                </Styled.RegionLanguageLink>
                {' | '}
                <Styled.RegionLanguageLink
                  onClick={() => {
                    cookies.set('region', item.abbreviation?.toLowerCase(), {
                      path: '/',
                      expires: new Date(
                        date.getFullYear() + 1,
                        date.getMonth(),
                        date.getDate()
                      ),
                    });
                    cookies.set('language', 'fr-CA', {
                      path: '/',
                      expires: new Date(
                        date.getFullYear() + 1,
                        date.getMonth(),
                        date.getDate()
                      ),
                    });
                    frenchSlug
                      ? (window.location.href = frenchSlug)
                      : window.location.reload();
                  }}
                >
                  FR
                </Styled.RegionLanguageLink>
              </Styled.RegionLanguageCell>
            </Styled.RegionLanguageRow>
          ))}
        </Styled.RegionLanguageTable>
      )}
    </Styled.AccountTypeModal>
  );
};
export default AccountTypePopup;
