import React from 'react';
import * as Styled from './subNavigationsStyle';
import IconArrowDown from '../../../../images/icon-arrow-down.png';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
  isSmallDevice,
} from '../../../../utils';
import { useLocation } from '@reach/router';

const SubNavigation = ({ subNav, onMouseLeave }) => {
  const [showSubMenu, setShowSubMenu] = React.useState();
  const onHover = (e, index) => {
    setShowSubMenu(index);
  };
  const location = useLocation();
  return (
    <Styled.SubMenuList onMouseLeave={() => !isSmallDevice() && onMouseLeave()}>
      {subNav?.map((nav, index) => (
        <Styled.SubMenuListItem
          key={index}
          onMouseEnter={(e) => !isSmallDevice() && onHover(e, index)}
          onClick={() =>
            isSmallDevice() &&
            setShowSubMenu((prev) => {
              return prev === index ? false : index;
            })
          }
        >
          <Styled.SubMenuArrowImage
            src={IconArrowDown}
            className={
              nav?.subMenusWithPaths
                ? showSubMenu === index
                  ? 'active'
                  : ''
                : 'd-none'
            }
          />
          <Styled.SubMenuAnchor
            className={
              location?.pathname?.split('/')?.slice(0, 3).join('/') ===
              (nav?.textWithPath?.path ?? nav?.path)
                ? 'active'
                : ''
            }
            href={
              isExternalUrlHref(nav?.textWithPath?.path ?? nav?.path ?? '/')
                ? nav?.textWithPath?.path ?? nav?.path ?? '/'
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL +
                      (nav?.textWithPath?.path ?? nav?.path ?? '/')
                  )
            }
            target={
              isExternalUrl(nav?.textWithPath?.path ?? nav?.path ?? '/')
                ? '_blank'
                : '_self'
            }
          >
            {nav?.textWithPath?.text ?? nav?.text ?? ''}
          </Styled.SubMenuAnchor>
          {nav?.subMenusWithPaths && showSubMenu === index && (
            <Styled.SubSubMenuList>
              {nav?.subMenusWithPaths?.map((subNav, index) => (
                <Styled.SubSubMenuListItem key={index}>
                  <Styled.SubSubMenuAnchor
                    className={
                      (location?.pathname + location?.hash)?.includes(
                        subNav?.path
                      )
                        ? 'active'
                        : ''
                    }
                    href={
                      isExternalUrlHref(subNav?.path)
                        ? subNav?.path
                        : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + subNav?.path)
                    }
                    target={isExternalUrl(subNav?.path) ? '_blank' : '_self'}
                  >
                    {subNav?.text}
                  </Styled.SubSubMenuAnchor>
                </Styled.SubSubMenuListItem>
              ))}
            </Styled.SubSubMenuList>
          )}
        </Styled.SubMenuListItem>
      ))}
    </Styled.SubMenuList>
  );
};
export default SubNavigation;
