import { graphql, useStaticQuery } from 'gatsby';

const useRegionLanguage = () => {
  const regionLanguageData = useStaticQuery(graphql`
    query languageModalQuery {
      allContentfulUltramarCommercial(
        filter: { identifier: { type: { eq: "popup" } } }
      ) {
        edges {
          node {
            node_locale
            title
            slug
            identifier {
              type
            }
            sections {
              __typename
              ... on ContentfulRegions {
                id
                abbreviation
                title
                textWithPath {
                  text
                  path
                }
              }
            }
          }
        }
      }
    }
  `);

  return regionLanguageData;
};

export default useRegionLanguage;
