import { validLanguages, validRegions } from '../constants';

export const addTrailingSlash = (slug) => {
  if (!slug) {
    return;
  }
  if (slug?.includes('#') && slug[slug?.indexOf('#') - 1] !== '/') {
    return (
      slug?.substring(0, slug?.indexOf('#')) +
      '/' +
      slug?.substring(slug?.indexOf('#'), slug?.length)
    );
  } else if (slug?.includes('#') && slug[slug?.indexOf('#') - 1] === '/') {
    return slug;
  }
  return slug[slug?.length - 1] === '/' ? slug : slug?.concat('/');
};

export const isValidURLLanguage = (language) =>
  validLanguages?.includes(language) ? true : false;

export const isValidURLRegion = (region) =>
  region && validRegions?.includes(region?.toLowerCase()) ? true : false;

export const validNodeLocale = (language) =>
  language === 'fr' ? 'fr-CA' : 'en-US';

export const isSmallDevice = () =>
  typeof window !== 'undefined' && window.innerWidth < 992;

export const isYoutubeLink = (link) => {
  const regExp =
    /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  return link.match(regExp);
};

const isExternalUrl = (str) => {
  const isExternalReg = /^(http|https|mailto)/;
  if (!str) {
    return true;
  }
  if (str.includes('slp')) {
    return true;
  }
  return isExternalReg.test(String(str).toLowerCase()) ? true : false;
};

export const isExternalUrlHref = (str) => {
  const isExternalReg = /^(http|https|mailto|tel|#)/;
  if (!str) {
    return true;
  }
  return isExternalReg.test(String(str).toLowerCase()) ? true : false;
};

export default isExternalUrl;
