import styled from 'styled-components';

export const AccountTypeModal = styled.div`
  display: block;
  left: -75px;
  width: 300px;
  position: absolute;
  z-index: 99;
  top: 60px;
  height: auto;
  padding: 40px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  overflow: visible;
  @media (max-width: 1399px) {
    left: ${(props) => (props?.$region ? '-75px' : '-30px')};
    width: ${(props) => (props?.$region ? '300px' : '250px')};
  }
`;

export const AccountTypeModalArrow = styled.div`
  display: block;
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  transform: rotate(45deg);
  transition: all 0.25s ease-in-out;
`;

export const AccountTypeModalPara = styled.p`
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 26px;
`;

export const AccountTypeSelect = styled.div`
  padding-top: 10px;
`;
export const AccountTypeButton = styled.a`
  padding: 10px 20px;
  margin-bottom: 10px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  top: 0;
  /* padding: 15px 35px; */
  margin-right: 10px;
  font-family: 'MrAlex', sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: #00a6e8;
  border: 2px solid #00a6e8;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  @media (max-width: 1399px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 1199px) {
    font-size: 25px;
    line-height: 25px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 25px;
  }
  :active {
    top: -5px;
    color: #f7991c;
    border-color: #f7991c;
    box-shadow: 0 5px 0 0 #f7991c;
  }
  :hover {
    top: -5px;
    color: #f7991c;
    border-color: #f7991c;
    box-shadow: 0 5px 0 0 #f7991c;
  }
`;

export const RegionLanguageTable = styled.div`
  display: table;
  width: 100%;
`;

export const RegionLanguageCell = styled.div`
  display: table-cell;
  padding: 10px 0;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  &.active {
    font-family: 'HelveticaNeue-Bold', arial, sans-serif;
  }
  :last-child {
    text-align: right;
  }
`;

export const RegionLanguageRow = styled.div`
  display: table-row;
  :first-child {
    ${RegionLanguageCell} {
      padding-top: 0;
    }
  }
  :last-child {
    ${RegionLanguageCell} {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
`;

export const RegionLanguageLink = styled.a`
  text-decoration: none;
  font-size: 15px;
  display: inline-block;
  cursor: pointer;
  :hover {
    text-decoration: none;
    color: #f7991c;
  }
`;
