import * as React from 'react';
import * as Styled from './headerStyle';
import HeadBottom from './headBottom/headBottom';
import HeadMobile from './headMobile/headMobile';
import HeadTop from './headTop/headTop';
import useMenu from '../../hooks/useMenus';
import Cookies from 'universal-cookie';
import { validHomePageRoutes } from '../../constants';
import {
  isSmallDevice,
  isValidURLLanguage,
  validNodeLocale,
} from '../../utils';
import { useLocation } from '@reach/router';

const Header = ({ englishSlug, frenchSlug }) => {
  const location = useLocation();
  const cookies = new Cookies();

  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);

  const selectedLanguage =
    isValidURLLanguage(urlLanguage) && validNodeLocale(urlLanguage)
      ? validNodeLocale(urlLanguage)
      : cookies.get('language')
      ? cookies.get('language')
      : 'en-US';

  const unFilteredMenu = useMenu(selectedLanguage);

  const filteredMenu = unFilteredMenu.find((section) =>
    cookies.get('region')?.toUpperCase()
      ? section.abbreviation === cookies.get('region')?.toUpperCase()
      : section.abbreviation === 'NB'
  );
  const menu = filteredMenu?.sections[0];
  const [showNav, setShowNav] = React.useState(false);
  const [showSubMenu, setShowSubMenu] = React.useState(false);
  const [hoverIndex, setHoverIndex] = React.useState();
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    handleScroll();
  }, []);

  React.useEffect(() => {
    if (!showNav) {
      setShowSubMenu(false);
    }
  }, [showNav]);

  React.useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onMouseEnter = (key, index) => {
    setShowSubMenu(
      !!menu.navigations.filter(
        (item) => item.entryWithPath?.text === key && item.subNavigations
      )[0]
    );
    setHoverIndex(index);
  };

  const onMouseLeave = () => {
    setShowSubMenu(false);
    setHoverIndex(null);
  };

  return (
    <>
      <Styled.Header
        className={`${showSubMenu ? 'open' : ''} ${
          scrollPosition && !isSmallDevice() ? 'scrolled' : ''
        } ${validHomePageRoutes?.includes(location?.pathname) ? 'home' : ''}`}
      >
        <HeadMobile
          showNav={showNav}
          setShowNav={setShowNav}
          imageData={menu?.imageWithPath}
        />
        <div className="d-none d-md-block">
          <HeadTop
            englishSlug={englishSlug}
            frenchSlug={frenchSlug}
            scrollPosition={scrollPosition}
            showSubMenu={showSubMenu}
          />
        </div>
        <Styled.Clear></Styled.Clear>
        <HeadBottom
          englishSlug={englishSlug}
          frenchSlug={frenchSlug}
          showSubMenu={showSubMenu}
          showNav={showNav}
          navigationData={menu}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          hoverIndex={hoverIndex}
          imageData={menu?.imageWithPath[0]}
        />
      </Styled.Header>
    </>
  );
};

export default Header;
