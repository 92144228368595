import React from 'react';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../../utils';
import HeadBottomLogo from '../headBottomLogo/headBottomLogo';
import * as Styled from '../headerStyle';

function HeadMobile({ showNav, setShowNav, imageData }) {
  return (
    <Styled.HeadMobile>
      <HeadBottomLogo imageData={imageData[0]} />
      <Styled.HeadMobileLogo>
        <a
          href={
            isExternalUrlHref(imageData[1]?.path)
              ? imageData[1]?.path
              : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + imageData[1]?.path)
          }
          target={isExternalUrl(imageData[1]?.path) ? '_blank' : '_self'}
          rel="noreferrer"
        >
          <img
            src={imageData[1]?.image?.file?.url}
            alt={imageData[1]?.image?.title}
          />
        </a>
      </Styled.HeadMobileLogo>
      <Styled.HeadNavMobile>
        <a id="navMobile-button" onClick={() => setShowNav((prev) => !prev)}>
          <div className={`navCircle`}>
            <div className={`navHamburger bar1 ${showNav && 'open'}`}></div>
            <div className={`navHamburger bar2 ${showNav && 'open'}`}></div>
            <div className={`navHamburger bar3 ${showNav && 'open'}`}></div>
          </div>
        </a>
      </Styled.HeadNavMobile>
    </Styled.HeadMobile>
  );
}

export default HeadMobile;
